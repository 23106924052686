import React, {FunctionComponent}         from 'react'
import {setProjectColourDarkButtonStyles} from '../../utilities/project-colour-styles'
import {ProjectInfo}                      from './ProjectInfoPage'
import {SanityLinkHelper}                 from '@open-law/open-law-shared'

interface ProjectPageHeroProps {
    projectInfo: ProjectInfo
}

const ProjectPageHero: FunctionComponent<ProjectPageHeroProps> = (props) => {

    const {projectInfo} = props
    const {
        projectColour,
        projectTagline,
        title,
        projectImage,
        ctaButtonOne,
        ctaButtonTwo
    } = projectInfo

    return projectInfo && (
        <div className={`relative bg-white overflow-hidden`}>
            <div className={`max-w-7xl mx-auto`}>
                <div
                    className={`relative bg-white z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32`}>
                    <svg
                        className={`z-10 hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2`}
                        fill="currentColor" viewBox="0 0 100 100" preserveAspectRatio="none" aria-hidden="true">
                        <polygon points="50,0 100,0 50,100 0,100"/>
                    </svg>
                    <main
                        className={`pt-10 mx-auto max-w-7xl px-4 sm:pt-12 sm:px-6 md:pt-16 lg:pt-20 lg:px-8 xl:pt-28`}>
                        <div className={`z-10 sm:text-center lg:text-left`}>
                            <h1 className={`text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl`}>
                                <span className={`block xl:inline`}>{title}</span>
                            </h1>
                            <p className={`mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0`}>
                                {projectTagline}
                            </p>
                            <div className={`mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start`}>
                                {
                                    ctaButtonOne?.buttonText && (
                                        <div
                                            className={`rounded-md shadow ${setProjectColourDarkButtonStyles(projectColour)}`}>
                                            <a href={SanityLinkHelper(ctaButtonOne.url)}
                                               className={`w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md  md:py-4 md:text-lg md:px-10`}>
                                                {ctaButtonOne.buttonText}
                                            </a>
                                        </div>
                                    )
                                }
                                {
                                    ctaButtonTwo?.buttonText && (
                                        <div
                                            className={`rounded-md shadow mt-3 sm:mt-0 sm:ml-3 bg-gray-100 hover:bg-gray-300`}>
                                            <a href={SanityLinkHelper(ctaButtonTwo.url)}
                                               className={`w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md  md:py-4 md:text-lg md:px-10`}>
                                                {ctaButtonTwo.buttonText}
                                            </a>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </main>
                </div>
            </div>
            <div className={`lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2`}>
                {
                    projectImage &&
                    <img className={`h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full`}
                         src={projectImage.asset.url} alt=""/>
                }
            </div>
        </div>
    )


}


export default ProjectPageHero
