import {
    PortableTextRenderer,
    ProjectColours,
    SanityLinkHelper
}                                           from '@open-law/open-law-shared'
import React, { FunctionComponent }         from 'react'
import { setProjectColourDarkButtonStyles } from '../../utilities/project-colour-styles'
import ProjectPageHero                      from './ProjectPageHero'


type ProjectCtaButton = { buttonText: string, url: string }

export type ProjectInfo = {
    id: string
    _rawProjectSummary: unknown
    title: string
    shortTitle: string
    projectTagline: string
    ctaButtonOne: ProjectCtaButton
    ctaButtonTwo: ProjectCtaButton
    projectLead: {
        name: string
        image: unknown
    }
    projectImage: { asset: { url: string } }
    slug: { current: string }
    projectHeadlines: string[]
    projectBanner: any
    projectLogo: any
    projectColour: ProjectColours
}

interface ProjectInfoPageProps {
    projectInfo: ProjectInfo
    relatedBlogs: any[]
}

const ProjectInfoPage: FunctionComponent<ProjectInfoPageProps> = (props) => {
    const {projectInfo, relatedBlogs}                 = props
    const {ctaButtonOne, ctaButtonTwo, projectColour} = projectInfo

    // console.log('Project info page props: ', props)
    return (
        <section>
            {
                projectInfo ? <ProjectPageHero projectInfo={projectInfo}/> : null
            }
            <div className="py-16 bg-theme-grey-light overflow-hidden">
                <div className="max-w-7xl mx-auto px-4 space-y-8 sm:px-6 lg:px-8">
                    <div className={'flex justify-center'}>
                        <div className="text-base max-w-3xl text-gray-500 font-light">
                            <PortableTextRenderer projectColour={projectInfo.projectColour} className={'portable-text'}
                                                  blocks={projectInfo._rawProjectSummary}/>
                            <div className="mt-10 flex text-base max-w-prose mx-auto lg:max-w-none">
                                {
                                    ctaButtonOne?.buttonText ? (
                                        <div
                                            className={`rounded-md shadow ${setProjectColourDarkButtonStyles(projectColour)}`}>
                                            <a href={SanityLinkHelper(ctaButtonOne.url)}
                                               className={`w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md`}>
                                                {ctaButtonOne.buttonText}
                                            </a>
                                        </div>
                                    ) : null
                                }
                                {
                                    ctaButtonTwo?.buttonText ? (
                                        <div className="rounded-md shadow ml-4 bg-gray-100 hover:bg-gray-300">
                                            <a href={SanityLinkHelper(ctaButtonTwo.url)}
                                               className={`w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md`}>
                                                {ctaButtonTwo.buttonText}
                                            </a>
                                        </div>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ProjectInfoPage
