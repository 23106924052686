import { graphql }                      from 'gatsby'
import React, { FunctionComponent }     from 'react'
import ProjectInfoPage, { ProjectInfo } from '../components/projects/ProjectInfoPage'
import Layout                           from '../containers/layout'

export const projectPageQuery = graphql`
    query ProjectPageTemplateQuery($id: String!, $title: String!) {
        #        menuItems: sanityNavigationMenu(id: {eq: "-653097ce-bd1a-5011-b67f-57ceab2438da"}){
        #            _rawItems(resolveReferences: {maxDepth: 10})
        #        }
        projectInfo: sanityProject(id: { eq: $id }) {
            id
            _rawProjectSummary(resolveReferences: {maxDepth: 10})
            title
            ctaButtonOne {
                buttonText
                url {
                    external
                    internal {
                        ... on SanityPost {
                            id
                            slug {
                                current
                            }
                        }
                        ... on SanityRoute {
                            slug {
                                current
                            }
                        }
                    }
                }
            }
            ctaButtonTwo {
                buttonText
                url {
                    external
                    internal {
                        ... on SanityPost {
                            id
                            slug {
                                current
                            }
                        }
                        ... on SanityRoute {
                            slug {
                                current
                            }
                        }
                    }
                }
            }
            shortTitle
            projectImage {
                ...SanityImage
            }
            projectBanner {
                ...SanityImage
            }
            projectLogo {
                ...SanityImage
            }
            slug {
                current
            }
            projectTagline
            projectHeadlines
            projectLead {
                name
                image {
                    ...SanityImage
                }
                slug {
                    current
                }
            }
            projectColour
        }
        relatedBlogs: allSanityPost(
            filter: {
                categories: {elemMatch: {title: {eq: $title}}}
            },
            sort: {fields: publishedAt, order: DESC},
            limit: 5
        ) {
            edges {
                node {
                    title
                    slug {
                        current
                    }
                    mainImage {
                        ...SanityImage
                    }
                    authors {
                        name
                    }
                    publishedAt(formatString: "DD-MMM-YYYY")
                }
            }
        }
    }
`

interface ProjectPageTemplateProps {
    data: { menuItems: unknown, projectInfo: ProjectInfo, relatedBlogs: any[] }
    errors: unknown
}

const ProjectPageTemplate: FunctionComponent<ProjectPageTemplateProps> = (props) => {
    const {data, errors} = props
    return (
        <Layout navMenuItems={data.menuItems}>
            {
                data.projectInfo &&
                <ProjectInfoPage projectInfo={data.projectInfo} relatedBlogs={data.relatedBlogs}/>
            }
        </Layout>
    )
}

export default ProjectPageTemplate
